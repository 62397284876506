.stepper-wrapper :global .chakra-stack > div {
  cursor: default;
}

.stepper-wrapper :global .chakra-stack .css-17dbl7j:hover {
  background: white;
}

:global(.stepper-content) {
  min-height: 33em;
  padding-bottom: 15px;
}