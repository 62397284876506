@import '../shared/CKEditor/config/EditorStyleConfig.module.css';

:global(#document-templates-container) {
  .sections-editor__heading-section {
    background-color: white;
    width: 99%;
    z-index: 3;
    padding: 0;
  }

  .sections-editor__heading-section :global .ck.ck-toolbar_grouping {
    width: 100%;
    background: white;
    border: none;
    box-shadow: 0 8px 4px -4px rgb(0 0 0 / 10%);
    margin-top: -15px;
    margin-bottom: 5px;
  }

  .sections-editor__heading-section :global div.ck.ck-heading-dropdown ul.ck-list {
    font-size: 14px;
    font-family: 'Open Sans', 'Noto Sans', 'Noto Emoji', 'Noto Sans Math', 'Noto Sans JP', 'Noto Sans KR',
      'Noto Sans SC', sans-serif;
  }

  .sections-editor__heading-section :global button.ck.ck-button.ck-heading_p.ck-button_with-text span.ck-button__label {
    font-size: 1em;
  }

  .sections-editor__heading-section
    :global
    button.ck.ck-button.ck-heading_h1.ck-button_with-text
    span.ck-button__label {
    font-size: 1.4em !important;
    font-weight: 600;
  }

  .sections-editor__heading-section
    :global
    button.ck.ck-button.ck-heading_h2.ck-button_with-text
    span.ck-button__label {
    font-size: 1.3em;
    font-weight: 600;
  }

  .sections-editor__heading-section
    :global
    button.ck.ck-button.ck-heading_h3.ck-button_with-text
    span.ck-button__label {
    font-size: 1.2em;
    font-weight: 600;
  }

  .sections-editor__heading-section
    :global
    button.ck.ck-button.ck-heading_h4.ck-button_with-text
    span.ck-button__label {
    font-size: 1.2em;
    font-style: italic;
    font-weight: 600;
  }

  .sections-editor__heading-section :global .ck-exportpdf__spinner-container {
    margin-right: 15px;
  }

  .sections-editor__document__section-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: var(--DocumentEditorPageBackground);
  }

  .sections-editor__document__section-content :global ol[multilevel] {
    list-style: none !important;
    clear: both;
  }

  .sections-editor__document__section-content :global h1.section-heading {
    font-size: 1.6em;
    clear: both;
    padding-top: 20px !important;
    padding-bottom: 10px;
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline {
    /* subtract 20mm margin on the left and right */
    width: calc(var(--A4PageWidth) - 2 * var(--DocumentPageInlineMargin));
    padding-inline: var(--DocumentInlinePadding);
    margin: var(--A4PagePaddingBlock) auto;
    height: auto;
    background-color: var(--DocumentEditorPageBackground);
    overflow: hidden;
  }

  .sections-editor__document__section-content
    :global(.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused),
  :global(.ck.ck-editor__editable_inline) {
    outline: none;
    border: none !important;
    box-shadow: none;
  }

  .sections-editor__document__section-content:global(.landscape) :global(.ck.ck-editor__editable_inline) {
    width: var(--A4PageHeight);
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline p {
    margin-bottom: var(--DocumentParagraphPaddingBottom) !important;
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline .table p {
    margin-bottom: 0 !important;
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline .table ul {
    padding-left: 3.3em !important;
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline .table ol {
    padding-left: 3.3em !important;
  }

  .sections-editor__document__section-content :global .ck.ck-editor__editable_inline pre[data-language]:after {
    content: none;
  }

  .sections-editor__document__section-content :global div.ck-content {
    width: 100%;
  }

  .sections-editor__document__section-content :global .locked-section-headers {
    cursor: not-allowed;
  }

  .sections-editor__document__section-content :global h1 {
    font-size: 1.4em;
  }

  .sections-editor__document__section-content :global .ck.ck-reset_all.ck-widget__type-around {
    display: block !important;
  }

  .sections-editor__document__section-content :global(.progress-indication::before) {
    content: attr(percentage) '%';
    display: inline-flex;
    vertical-align: top;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100%;
    font-weight: 600;
    line-height: 1.2;
    outline: transparent solid 2px;
    outline-offset: 2px;
    min-height: 20px;
    min-width: 20px;
    font-size: 12px;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    border-radius: 10px;
    color: #2e2e33;
    background: #f2f3f5;
    box-shadow: #e3e3e5 0 0 0 1px inset;
    margin-inline: 5px;
  }

  .sections-editor__document__section-content :global(.upload-complete-icon) {
    display: inline !important;
    right: unset !important;
    top: unset !important;
    margin-inline: 5px;
    animation-duration: 2s, 2s !important;
  }

  :global(.ck-content > ul),
  :global(.ck-content > ol) {
    padding-left: 3.3em;
  }

  :global(.ck-content > ol) {
    padding-left: 3.3em;
  }

  :global(.ck-content table td) {
    padding: 3px !important;
  }

  :global(input[type='checkbox']) {
    margin: 0 !important;
    margin-right: 4px !important;
    vertical-align: middle;
  }

  /*TODO change class name to .ck-table-column-resizer when upgrading to newer cke version */
  :global(.ck.ck-editor__editable .table .table-column-resizer) {
    top: 0 !important;
    bottom: 0 !important;
  }
}
